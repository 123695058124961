.App {
  font-family: sans-serif;
  /* text-align: center; */
  /* width: 99vw; */
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  position: relative;
}

.container,
#interactive.viewport {
  width: 400px;
  height: 300px;
}

/* #interactive.viewport canvas,
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */
/* #interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 700px;
  height: 300px;
} */
