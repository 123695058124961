.login-container {
  background-image: url('./image/bg.jpg'); /* Ganti dengan path ke gambar Anda */
  background-size: cover; /* Mengisi seluruh area latar belakang */
  background-repeat: no-repeat; /* Menghindari pengulangan gambar */
  height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: 'darkslateblue'; */
  }



  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .card {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: transparent;
  }
  
  .card-title {
    font-family: inherit;
    font-weight: bold;
    margin-bottom: 10px;
    color: #e5e1e1;
  }
  
  .form-container input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
    text-align: center;

  }
  
  .form-container button {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
    background-color: #007bff;
    color: #fff;
  }
  
  /* Add responsive styles as needed */
  



.btnLogin {
  background-color: #28b1e8;
}
  .container-bottom {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Sesuaikan sesuai kebutuhan desain Anda */
  }
  
  .logo-container div {
    /* Gaya untuk logo */
  }
  
  .form-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* background-color: #f9f9f9; */
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .input-container {
    margin-bottom: 10px;
    text-align: left;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005599;
  }
  